<template>

  <div class="statistics__stat">
    <div>
      <div class="statistics__stat-name">{{ name }}</div>
      <div class="statistics__value">{{ hasCurrency ? currencyIcon(String(currency)) : '' }}{{ current }}</div>
    </div>
    <div class="u-text-right">
      <div class="statistics__no-diff" v-if="difference === null">-</div>
      <div class="statistics__percentage" v-else :class="[difference > 0 ? 'is-positive' : 'is-negative']">{{ difference }}%</div>
      <div class="statistics__pre-value">({{ hasCurrency ? currencyIcon(String(currency)) : '' }}{{ previous }})</div>
    </div>
  </div>

</template>

<script>

export default {
  name: "StatisticWeekStat",
  props: {
    current: {
      required: true
    },
    currency: {
      required: true
    },
    previous: {
      required: true
    },
    difference: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    hasCurrency: {
      type: Boolean,
    },
  },
  methods:{
    currencyIcon(currencyName){
       let currency
        switch (currencyName) {
          case "USD":
           currency = "$"
          break;
          case "CHF":
           currency = "CHF"
          break;
          case "GBP":
           currency = "£"
          break;
          case "AUD":
           currency = "$"
          break;
          default:
            currency = "€"
            break;
          }
          return currency
      },
  }
}
</script>