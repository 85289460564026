<template>
  <div v-if="is_loaded">

    <d-card v-if="active_provider_index === null">
      <d-card-body :subtitle="$t('statistics.no_provider.title')">
        {{ $t('statistics.no_provider.message') }}
      </d-card-body>

    </d-card>
    <div v-else class="statistics">

      <app-loading v-if="!orders_loaded" />
      <div class="statistics__dashboard">

        <div class="statistics__overview">

          <div class="statistics__heading" v-if="provider">

            <div class="statistics__info">
              <strong>{{ $t('statistics.greeting') }}</strong><br/>
              {{ $t('statistics.info_message', [provider.name]) }}
            </div>

            <div>
              <div class="statistics__ranger">
                <div class="statistics__label">{{ $t('statistics.range') }}:</div>
                <d-form-select v-model="rangePicker" :options="rangeOptions" />
              </div>
              <div class="stats-card__pickers" v-if="rangePicker === 'pickers'">
                <date-picker v-model="startDate"></date-picker>
                <date-picker v-model="endDate"></date-picker>
              </div>
            </div>

          </div>

          <div class="statistics__cards" v-if="weekStatistics">

            <statistic-week-stat :name="$t('statistics.amount_title')"
                                 :current="number_format(weekStatistics.amount.current,0,',','.')"
                                 :previous="number_format(weekStatistics.amount.previous,0,',','.')"
                                 :difference="weekStatistics.amount.difference"
                                 :currency= "[provider.currency]"
            />

            <statistic-week-stat :name="$t('statistics.total_title')"
                                 :current="number_format(weekStatistics.total.current,2,',','.')"
                                 :previous="number_format(weekStatistics.total.previous,2,',','.')"
                                 :difference="weekStatistics.total.difference"
                                 :currency= "[provider.currency]"
                                 has-currency
            />

            <statistic-week-stat :name="$t('statistics.average_title')"
                                 :current="number_format(weekStatistics.average.current,2,',','.')"
                                 :previous="number_format(weekStatistics.average.previous,2,',','.')"
                                 :difference="weekStatistics.average.difference"
                                 :currency= "[provider.currency]"
                                 has-currency
            />

          </div>

        </div>

        <div class="statistics__card  statistics__total">
          <h2 class="statistics__title">
            {{ $t('statistics.total_title') }}
          </h2>
          <div class="statistics__chart">
            <canvas ref="chartElOrderTotal"></canvas>
          </div>
        </div>

        <div class="statistics__card  statistics__amount">
          <h2 class="statistics__title">
            {{ $t('statistics.amount_title') }}
          </h2>
          <div class="statistics__chart">
            <canvas ref="chartElOrderAmount"></canvas>
          </div>
        </div>

      </div>
      <ServiceLocations  :startDate="this.startDate" :endDate="this.endDate"/>
    </div>

  </div>
</template>

<script>
import graphqlCalls from "~/graphql";
import mixins from "~/mixins";
import AppLoading from "../components/AppLoading";
import {mapProviderStateFields} from "~/store";
import {providerOrderSettings} from "../graphql/providers/GetProviderOrders";
import Chart from 'chart.js/auto';
import dayjs from "dayjs";
import StatisticWeekStat from "./../components/StatisticWeekStat";
import DatePicker from "../components/DatePicker";
import IsoWeek from "dayjs/plugin/isoWeek"
import ServiceLocations from "./ServiceLocations";

dayjs.extend(IsoWeek)

export default {
  name: "Statistics",
  components: {DatePicker, StatisticWeekStat, AppLoading,ServiceLocations},
  mixins: [graphqlCalls, mixins],

  data() {
    return {
      orders: [],
      orders_loaded: false,

      chartOrderAmount: null,
      chartOrderTotal: null,

      weekStatistics: null,

      rangePicker: 'this_week',
      compareStartDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      rangeOptions: [
        {
          text: this.$t('statistics.ranges.ThisWeek'),
          value: "this_week"
        },
        {
          text: this.$t('statistics.ranges.LastWeek'),
          value: "last_week"
        },
        {
          text: this.$t('statistics.ranges.ThisMonth'),
          value: "this_month"
        },
        {
          text: this.$t('statistics.ranges.LastMonth'),
          value: "last_month"
        },
        {
          text: this.$t('statistics.ranges.ThisYear'),
          value: "this_year"
        },
        {
          text: this.$t('statistics.ranges.LastYear'),
          value: "last_year"
        },
        {
          text: this.$t('statistics.ranges.pick_dates'),
          value: "pickers"
        }
      ],
      labelsData: []
    };
  },

  computed: {
    ...mapProviderStateFields([
      'is_loaded',
      'active_provider_index',
      'providers',
    ]),

    provider() {
      if(this.active_provider_index === null) return null
      return this.providers[this.active_provider_index]
    }
  },

  methods: {
    loadServiceLocations: async function (providerId) {
    
      let serviceLocationsIds = []
   await this.fetchServiceLocations(providerId,this.date).then((response) => {
      response.data.provider.serviceLocations.forEach((serviceLocation)=>{
        serviceLocationsIds.push(serviceLocation.id)
        
      })
    })
 
    if(serviceLocationsIds.length > 0) {
      this.loadOrders(providerId,serviceLocationsIds)
    }
  },
    loadOrders: function (providerId,serviceLocationsIds) {

      const self = this;
      this.orders_loaded = false

      const settings = this.deepClone(providerOrderSettings);
      settings.filters.status = 'COMPLETED';
      settings.sorting.sortBy = 'created_at';
      settings.sorting.direction = 'Descending';
      settings.filters.service_locations_ids = serviceLocationsIds
   
      

      this.fetchOrderStatistics(providerId, settings).then((response) => { //heavy query
        

        self.orders = response.data.orders ?? []
       
        self.orders.forEach((order) => {
          order.day = this.makeDayjsDate(order.createdAt)
        })

        const orderDates = self.createChartData();
     
        self.determineWeekStatistics(this.deepClone(orderDates))

        // Determine if we redraw the charts or render them
        if(this.chartOrderAmount) self.redrawCharts(orderDates)
        else self.renderCharts(orderDates)

        self.orders_loaded = true

      })
    },

    createChartData: function () {

      const orderDates = {}

      this.orders.forEach((order) => {
        order.date = order.day.format('DD/MM/YYYY')
        order.price.amount = parseFloat(order.price.amount)

      

        if(orderDates[order.date]) orderDates[order.date].orders.push(order)
        else orderDates[order.date] = {
          orders: [order]
        }
      
      })


      let compareDates = {}
      let dates = {}
      let currentDay

      // let currentDay = firstDate.clone()
      if(this.rangePicker !== 'pickers') currentDay = dayjs(this.compareStartDate)
      else currentDay = dayjs(this.startDate)

      let groupByDay = true
      if(currentDay.diff(this.endDate, 'd') < -300) groupByDay = false

      while(currentDay <= this.endDate) {

        // If date present, calculate average, total and amount
        if(orderDates[currentDay.format('DD/MM/YYYY')]) {

          const orderDateObject = orderDates[currentDay.format('DD/MM/YYYY')]
          orderDateObject.amount = orderDateObject.orders.length

          let total = 0;
        
          orderDateObject.orders.forEach((order) => {

            total += order.price.amount
          
          })

          orderDateObject.total = parseFloat(total.toFixed(2))

          orderDateObject.date = currentDay

          if(currentDay < this.startDate) compareDates[currentDay.format('YYYYMMDD')] = orderDateObject
          else dates[currentDay.format('YYYYMMDD')] = orderDateObject
        }
        else {
          const emptyDay = {
            date: currentDay,
            orders: [],
            amount: 0,
            total: 0,
          }

          if(currentDay < this.startDate) compareDates[currentDay.format('YYYYMMDD')] = emptyDay
          else dates[currentDay.format('YYYYMMDD')] = emptyDay
        }

        currentDay = currentDay.add(1, 'd')
      }

      if(!groupByDay) {

        const weekDates = {}
        const prevWeekDates = {}

        Object.values(dates).forEach((date) => {

          if(weekDates[date.date.isoWeek() + '/' + date.date.isoWeekYear()]) {
            const monthDate = weekDates[date.date.isoWeek() + '/' + date.date.isoWeekYear()];

            monthDate.orders = [...monthDate.orders, ...date.orders]
            monthDate.amount += date.amount
            monthDate.total += date.total

          }
          else weekDates[date.date.isoWeek() + '/' + date.date.isoWeekYear()] = date
        })

        Object.values(compareDates).forEach((date) => {

          if(prevWeekDates[date.date.isoWeek() + '/' + date.date.isoWeekYear()]) {
            const monthDate = prevWeekDates[date.date.isoWeek() + '/' + date.date.isoWeekYear()];

            monthDate.orders = [...monthDate.orders, ...date.orders]
            monthDate.amount += date.amount
            monthDate.total += date.total

          }
          else prevWeekDates[date.date.isoWeek() + '/' + date.date.isoWeekYear()] = date
        })

        dates = weekDates
        compareDates = prevWeekDates
      }


      const labels = []
      const orderAmounts = []
      const orderTotals = []

      Object.values(dates).forEach((date) => {

        if(groupByDay) labels.push(date.date.format('DD/MM/YY'))
        else labels.push(date.date.isoWeek() + '/' + date.date.isoWeekYear())

        orderAmounts.push(date.amount)
        orderTotals.push(date.total)
      })

      const prevOrderAmounts = []
      const prevOrderTotals = []

      Object.values(compareDates).forEach((date) => {
        prevOrderAmounts.push(date.amount)
        prevOrderTotals.push(date.total)
      })
    this.labelsData = labels
      return {
        labels: labels,
        amounts: orderAmounts,
        totals: orderTotals,
        previousSet: {
          amounts: prevOrderAmounts,
          totals: prevOrderTotals,
        }
      }

    },

    renderCharts: function (data) {

      const chartElOrderAmount = this.$refs.chartElOrderAmount
      const chartElOrderTotal = this.$refs.chartElOrderTotal

      const defaultChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        }
      }

      this.chartOrderAmount = new Chart(chartElOrderAmount, {
        type: 'bar',
        data: {
          labels: data.labels,
          datasets: [{
            data: data.amounts,
            backgroundColor: '#05b0f5',
          }]
        },
        options: {...defaultChartOptions, ...{
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  stepSize: 1
                },
              }
            }
          }},
      });

      this.chartOrderTotal = new Chart(chartElOrderTotal, {
        type: 'line',
        data: {
          labels: data.labels,
          datasets: [{
            data: data.totals,
            fill: false,
            tension: 0.1,
            borderColor: '#05b0f5',
          }]
        },
        options: {
          ...defaultChartOptions, ...{
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value) {
                    return '€' + value;
                  }
                },
              }
            }
          },
        }
      });
    },

    redrawCharts: function (data) {

      this.chartOrderAmount.data.labels = data.labels
      this.chartOrderAmount.data.datasets[0].data = data.amounts
      this.chartOrderAmount.update()

      this.chartOrderTotal.data.labels = data.labels
      this.chartOrderTotal.data.datasets[0].data = data.totals
      this.chartOrderTotal.update()
    },

    determineWeekStatistics: function (data) {

      const total = {
        current: data.amounts.length === 0 ? 0 : data.totals.reduce((a,b) => a + b),
        previous: data.previousSet.amounts.length === 0 ? 0 : data.previousSet.totals.reduce((a,b) => a + b),
      }

      const amount = {
        current: data.amounts.length === 0 ? 0 : data.amounts.reduce((a,b) => a + b),
        previous: data.previousSet.amounts.length === 0 ? 0 : data.previousSet.amounts.reduce((a,b) => a + b),
      }

      const average = {
        current: total.current === 0 ? 0 : parseFloat((total.current / amount.current).toFixed(2)),
        previous: total.previous === 0 ? 0 : parseFloat((total.previous / amount.previous).toFixed(2)),
      }

      if(total.current === 0 || total.previous === 0) {
        total.difference = null
        amount.difference = null
        average.difference = null
      }
      else {
        total.difference = this.calculateDifference(total.current, total.previous)
        amount.difference = this.calculateDifference(amount.current, amount.previous)
        average.difference = this.calculateDifference(average.current, average.previous)
      }

      this.weekStatistics = {
        total: total,
        amount: amount,
        average: average,
      }

    },

    calculateDifference: function (a, b) {

      let d = (a / b) * 100;

      if(d === 100) return null
      else if(d > 100) return parseFloat((d - 100).toFixed(2))
      else return parseFloat(((100 - d) * -1).toFixed(2))
    }

  },

  created() {
    this.compareStartDate = dayjs().subtract(14, 'd').toDate()
    this.startDate = dayjs().subtract(7, 'd').toDate()
    this.endDate = dayjs().endOf('d').toDate()
  },

  mounted() {
 
    if(this.provider) {
  
     // this.loadOrders(this.provider.id)
      this.loadServiceLocations(this.provider.id)
    }
  },

  watch: {
    provider: function (val) {
      if(!val) return;
     // this.loadOrders(val.id);
      this.loadServiceLocations(val.id);
    },
    rangePicker: function () {

      switch (this.rangePicker) {

          case 'this_week':
            this.compareStartDate = dayjs().startOf('w').subtract(1, 'w').toDate()
            this.startDate = dayjs().startOf('w').toDate()
            this.endDate = dayjs().endOf('w').toDate()
            break;

          case 'last_week':
            this.compareStartDate = dayjs().startOf('w').subtract(2, 'w').toDate()
            this.startDate = dayjs().startOf('w').subtract(1, 'w').toDate()
            this.endDate = dayjs().endOf('w').subtract(1, 'w').toDate()
            break;

          case 'this_month':
            this.compareStartDate = dayjs().startOf('M').subtract(1, 'M').toDate()
            this.startDate = dayjs().startOf('M').toDate()
            this.endDate = dayjs().endOf('M').toDate()
            break;

          case 'last_month':
            this.compareStartDate = dayjs().startOf('M').subtract(2, 'M').toDate()
            this.startDate = dayjs().startOf('M').subtract(1, 'M').toDate()
            this.endDate = dayjs().endOf('M').subtract(1, 'M').toDate()
            break;

          case 'this_year':
            this.compareStartDate = dayjs().startOf('y').subtract(1, 'y').toDate()
            this.startDate = dayjs().startOf('y').toDate()
            this.endDate = dayjs().endOf('y').toDate()
            break;

          case 'last_year':
            this.compareStartDate = dayjs().startOf('y').subtract(2, 'y').toDate()
            this.startDate = dayjs().startOf('y').subtract(1, 'y').toDate()
            this.endDate = dayjs().endOf('y').subtract(1, 'y').toDate()
            break;
      }
    },
    startDate: function () {

      if(typeof this.startDate === 'string') this.startDate = dayjs(this.startDate, "YYYY-MM-DD")
      if(this.compareStartDate > this.startDate) this.compareStartDate = this.startDate

      const orderDates = this.createChartData();
      this.determineWeekStatistics(this.deepClone(orderDates))
      this.redrawCharts(orderDates)
    },
    endDate: function () {

      if(typeof this.endDate === 'string') this.endDate = dayjs(this.endDate, "YYYY-MM-DD")

      const orderDates = this.createChartData();
      this.determineWeekStatistics(this.deepClone(orderDates))
      this.redrawCharts(orderDates)
    },
  }

};
</script>
